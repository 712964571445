const NumberHelpers = {
  /**
   * This implementation improves on Number.toFixed() by correctly rounding in cases like 2.55. The tradeoff, however,
   * is that we cannot handle very large numbers accurately.
   *
   * @ see the MDN docs (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed)
   *
   */
  toFixed(value, precisionParam) {
    const precision = precisionParam || 0;
    const power = Math.pow(10, precision);
    const absValue = Math.abs(Math.round(value * power));
    let result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

    if (precision > 0) {
      const fraction = String(absValue % power);
      const padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
      result += `.${ padding }${ fraction }`;
    }
    return result;
  },

  managerTeamProgressNumFormatter(value, isPercent) {
    if (isPercent) {
      return `${ Math.round(value) }%`;
    }
    return Math.round(value * 10) / 10;

  },

  // accepts string or number, must has at least 1 char to return true
  areAllCharsDigits(string) {
    return (/^\d+$/).test(string);
  },

  getPercentApproximationString(percent = 0, divisor = 0) {
    if (percent === 0) {
      if (divisor > 0) {
        return '< 0.1';
      }
      return 0;

    }
    return percent.toFixed(1);

  },

  filterInt(value) {
    // eslint-disable-next-line no-useless-escape
    if ((/^(\-|\+)?([0-9]+)$/).test(value)) {
      return Number(value);
    }
    return NaN;
  }
};

module.exports = NumberHelpers;
