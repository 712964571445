const UAParser = require('UAParser');

// a function for testing the properties of HTML DOM elements
// usage:    `featureExists(feature, tag)`
// example:  `featureExists('placeholder', 'input')`
const featureExists = (feature, tag) => {
  return feature in document.createElement(tag);
};

class OsDetector {
  constructor(globals = {}) {
    ({
      webkit: this.webkit,
      external: this.external,
      AxonifyAndroid: this.AxonifyAndroid,
      navigator: this.navigator
    } = globals);

    this.getInfo = this.getInfo.bind(this);
  }

  isIpadFallback() {
    return this.navigator && this.navigator.maxTouchPoints && this.navigator.maxTouchPoints > 2 && (/MacIntel/).test(this.navigator.platform);
  }

  getInfo(ua = '') {
    const os = {
      placeholder: featureExists('placeholder', 'input'),
      desktop: false,
      mobile: false,
      tablet: false,
      browser: '',
      version: null,
      msTeamsMobileApp: false,
      msTeamsDesktopApp: false,
      isInMobileApp: () => {
        return false;
      }
    };

    // ua-parser.js
    const parser = new UAParser(ua);

    os.isInMobileApp = () => {
      if (os.windowsphone) {
        try {
          this.external.notify(JSON.stringify({ action: 'none' }));
          return true;
        } catch (error) { /** nomnomnomnom error */ }
      } else if (this.webkit && this.webkit.messageHandlers && this.webkit.messageHandlers.axonifyMessageHandler) {
        return true;
      } else if (this.AxonifyAndroid != null) {
        return true;
      }
      return false;
    };

    // Mapping the new engine to our new device types
    const legacyMapping = {
      'mobile safari': 'safari',
      'chrome webview': 'chrome',
      waterfox: 'firefox'
    };

    // Map device types
    const device = parser.getDevice();
    if (device.type != null) {
      os.mobile = device.type === 'mobile';
      os.tablet = device.type === 'tablet';
      os.desktop = os.tablet;
    } else {
      // Otherwise, it must be a desktop
      os.desktop = true;
    }

    // Map browser data
    const browser = parser.getBrowser();
    if (browser['name'] == null) {
      browser['name'] = '';
    }

    os.browser = browser.name.toLowerCase();
    if (legacyMapping[os.browser] != null) {
      os.browser = legacyMapping[os.browser];
    }
    os.version = parseFloat(browser.version);

    // We need to account for document mode for our older versions of the library
    if (os.browser === 'ie') {
      const documentMode = (document.documentMode != null) ? document.documentMode : Infinity;
      os.version = Math.min(documentMode, os.version);
      os.ieVersion = os.version;
    }

    const operatingSystem = parser.getOS();
    const name = operatingSystem.name || '';
    const isWindowsPhone = name === 'Windows Phone';
    const isWindowsMobile = name === 'Windows Mobile';
    os.windowsphone = isWindowsPhone || isWindowsMobile;

    os.windows = name.indexOf('Windows') !== -1;

    if (os.windows) {
      os.windowsVersion = operatingSystem.version;
    }

    os.android = name === 'Android';
    os.ios = name === 'iOS';
    os.blackberry = name === 'BlackBerry';
    os.bb10 = os.blackberry;
    os.ipad = device.model === 'iPad' || !os.ios && this.isIpadFallback();
    os.elo = ua === 'Mozilla/5.0 (Linux; Android 7.1; EloView 2.0/MSM8953) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/54.0.2840.68 Mobile Safari/537.36';
    os.msTeamsMobileApp = ua.includes('TeamsMobile-iOS') || ua.includes('TeamsMobile-Android');
    os.msTeamsDesktopApp = !os.msTeamsMobileApp && ua.includes('Teams');

    if (os.ipad) {
      os.tablet = true;
    }

    // For Android, we want the OS version, not browser for compatibility reasons if the user is inside of
    // the mobile app, otherwise the browser version is enough
    if (os.android && os.isInMobileApp()) {
      os.version = parseFloat(parser.getOS().version, 10);
    }

    return os;
  }
}

module.exports = OsDetector;
