const logging = require('logging');
require('Marionette');
const { Wreqr } = require('Backbone');
const { wrap } = require('underscore');

const nbChannel = Wreqr.radio.channel('nativeBridge');

const nbRequest = nbChannel.reqres.request.bind(nbChannel.reqres);
const nbTrigger = nbChannel.vent.trigger.bind(nbChannel.vent);
const nbExecute = nbChannel.commands.execute.bind(nbChannel.commands);
const nbVentOnce = nbChannel.vent.once.bind(nbChannel.vent);

const ANDROID_BACK_DISABLER = () => {
  logging.warn('Android Back exit attempt! nom nom nom');
  return true;
};

const toggleAndroidBackDisabler = (toggle = false) => {
  if (isInThirdPartyApp()) {
    if (toggle) {
      registerBackHandler(ANDROID_BACK_DISABLER);
    } else {
      unregisterBackHandler(ANDROID_BACK_DISABLER);
    }
  }
};

const appHasLoaded = () => {
  nbTrigger('appHasLoaded');
};

const resetApp = () => {
  nbTrigger('resetApp');
};

const enableScalingInWebView = () => {
  nbTrigger('enableScalingInWebView');
};

const requestNotificationPayload = () => {
  nbTrigger('requestNotificationPayload');
}

const disableScalingInWebView = () => {
  nbTrigger('disableScalingInWebView');
};

const isInApp = () => {
  return nbRequest('isInApp');
};

const supportedOAuthProviders = () => {
  return nbRequest('supportedOAuthProviders');
};

const canDownload = () => {
  return nbRequest('canDownload');
}

const canPrint = () => {
  return nbRequest('canPrint');
}

const canShowAttachments = () => {
  return nbRequest('canShowAttachments');
};

const canStoreUserCredentials = () => {
  return nbRequest('canStoreUserCredentials');
};

const canOpenUrl = () => {
  return nbRequest('canOpenUrl');
};

const supportAutoSignIn = () => {
  return nbRequest('supportAutoSignIn');
};

const supportAppConfig = (key) => {
  return nbRequest('supportAppConfig', key);
}

const requestAppConfig = (key) => {
  return nbRequest('requestAppConfig', key);
}

const shouldShowReset = () => {
  return nbRequest('shouldShowReset');
};

const nativeAuthRequired = () => {
  return nbRequest('nativeAuthRequired');
};

const retrieveCredentials = () => {
  return nbRequest('retrieveCredentials');
};

const oauthLogin = () => {
  return nbRequest('oauthLogin');
};

const reauthenticateOAuth = () => {
  return nbRequest('reauthenticateOAuth');
};

const requestPushData = () => {
  return nbRequest('requestPushData');
};

const isInThirdPartyApp = () => {
  return nbRequest('isInThirdPartyApp');
};

const canShowNativeAndroidVideoFullscreen = () => {
  return nbRequest('canShowNativeAndroidVideoFullscreen');
};

const canOpenChildWebViews = () => {
  return nbRequest('canOpenChildWebViews');
};

const getNavPosition = () => {
  return nbRequest('getNavPosition');
};

const registerBackHandler = (handler) => {
  nbExecute('registerBackHandler', handler);
};

const unregisterBackHandler = (handler) => {
  nbExecute('unregisterBackHandler', handler);
};

const onAppDidBecomeActive = (handler) => {
  nbChannel.vent.listenTo(nbChannel.vent, 'appDidBecomeActive', handler);

  return () => {
    nbChannel.vent.stopListening(nbChannel.vent, 'appDidBecomeActive', handler);
  }
};

const onAppDidEnterBackground = (handler) => {
  nbChannel.vent.listenTo(nbChannel.vent, 'appDidEnterBackground', handler);

  return () => {
    nbChannel.vent.stopListening(nbChannel.vent, 'appDidEnterBackground', handler);
  }
};


const storeCredentials = (data) => {
  nbExecute('storeCredentials', data);
};

const clearCredentials = () => {
  nbExecute('clearCredentials');
};

const openUrl = (data) => {
  nbExecute('openUrl', data);
};

const authenticationRequired = (data) => {
  nbExecute('authenticationRequired', data);
};

const registerOnceUrlClosed = (handler) => {
  nbVentOnce('urlClosed', handler);
};

const canShowMobileReviews = () => {
  return nbRequest('canShowMobileReviews');
};

const getMobileAppInfo = () => {
  return nbRequest('getMobileAppInfo');
};

const showReviewMobileApp = (handler) => {
  nbExecute('showReviewMobileApp', handler);
};

const getIntegrationType = () => {
  return nbRequest('getIntegrationType');
};

const getMsTeamsContentUrl = () => {
  return nbRequest('getMsTeamsContentUrl');
};

const isMsTeamsIntegration = () => {
  return nbRequest('isMsTeamsIntegration');
};

const setSupportedFeatures = () => {
  return nbExecute('setSupportedFeatures');
};

const toggleSensitiveContent = (shouldHideSensitiveContent) => {
  app.nativeBridge?.sendMessage({
    action: 'toggleSensitiveContent',
    data: { shouldHideSensitiveContent: shouldHideSensitiveContent }
  })
}

const extendLightboxOptionsWithAndroidBackDismiss = (view, options = {}) => {
  const notCloseable = options.closeEsc === false && options.closeClick === false;

  if (!notCloseable) {
    const androidBackDismissModal = () => {
      view.$el.trigger('close');
      return true;
    };

    return Object.assign({}, options, {
      onLoad: wrap(options.onLoad, (onLoad = () => {}) => {
        registerBackHandler(androidBackDismissModal);
        onLoad();
      }),
      onClose: wrap(options.onClose, (onClose = () => {}) => {
        unregisterBackHandler(androidBackDismissModal);
        onClose();
      })
    });
  }

  return options;
};

module.exports = {
  toggleAndroidBackDisabler,
  appHasLoaded,
  resetApp,
  enableScalingInWebView,
  requestNotificationPayload,
  disableScalingInWebView,
  isInApp,
  supportedOAuthProviders,
  canDownload,
  canPrint,
  canShowAttachments,
  canStoreUserCredentials,
  canOpenUrl,
  supportAutoSignIn,
  supportAppConfig,
  requestAppConfig,
  shouldShowReset,
  nativeAuthRequired,
  retrieveCredentials,
  oauthLogin,
  reauthenticateOAuth,
  requestPushData,
  isInThirdPartyApp,
  canShowNativeAndroidVideoFullscreen,
  canOpenChildWebViews,
  getNavPosition,
  registerBackHandler,
  unregisterBackHandler,
  storeCredentials,
  clearCredentials,
  openUrl,
  authenticationRequired,
  registerOnceUrlClosed,
  canShowMobileReviews,
  getMobileAppInfo,
  showReviewMobileApp,
  getIntegrationType,
  getMsTeamsContentUrl,
  isMsTeamsIntegration,
  setSupportedFeatures,
  extendLightboxOptionsWithAndroidBackDismiss,
  onAppDidBecomeActive,
  onAppDidEnterBackground,
  toggleSensitiveContent
};
