const _ = require('underscore');

let instance = null;

class PropertyPool {
  propertyObj = {};

  setProperties(newObj) {
    return _.extend(this.propertyObj, newObj);
  }

  getProperty(property) {
    if (_.keys(this.propertyObj).length === 0) {
      throw new Error('Tenant Property Provider has not been initialized');
    }

    if (property === 'holidayTheme') {
      // Special code path for holiday stuff...
      const holidayTheme = this.propertyObj[property];

      const today = new Date();
      // Holiday theme starts Dec 1rd and ends Feb 28
      // day of month is 1-based while month is 0-based
      const holidayStart = new Date(today.getFullYear(), 10, 1);
      const holidayEnd = new Date(today.getFullYear(), 1, 28);
      const isHolidayTime = today >= holidayStart || today < holidayEnd;

      return holidayTheme && isHolidayTime;
    }
    return this.propertyObj[property];

  }

  hasProperties() {
    return Object.keys(this.propertyObj).length > 0;
  }
}

class TenantPropertyProvider {
  static PropertyPool = PropertyPool;

  /**
   * Retrieves the instance of TenantPropertyProvider.
   * If the instance is already created, returns the existing instance.
   * Otherwise, creates a new instance and returns it.
   * @returns {PropertyPool} The instance of TenantPropertyProvider.
   */
  static get() {
    return instance != null ? instance : (instance = new PropertyPool());
  }
}

module.exports = TenantPropertyProvider;
