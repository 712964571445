const LOG_LEVELS = {
  TRACE: {
    value: 0,
    name: 'TRACE'
  },
  DEBUG: {
    value: 1,
    name: 'DEBUG'
  },
  INFO: {
    value: 2,
    name: 'INFO'
  },
  WARN: {
    value: 3,
    name: 'WARN'
  },
  ERROR: {
    value: 4,
    name: 'ERROR'
  },
  FATAL: {
    value: 5,
    name: 'FATAL'
  }
};

const wrapLoggerFunction = (consoleFnName) => {
  return (...args) => {
    window.console[consoleFnName](...args);
  };
};

const LOG_LEVEL_CONSOLE_FUNCTION_MAP = {
  [LOG_LEVELS.TRACE.value]: wrapLoggerFunction('log'),
  [LOG_LEVELS.DEBUG.value]: wrapLoggerFunction('log'),
  [LOG_LEVELS.INFO.value]: wrapLoggerFunction('info'),
  [LOG_LEVELS.WARN.value]: wrapLoggerFunction('warn'),
  [LOG_LEVELS.ERROR.value]: wrapLoggerFunction('error'),
  [LOG_LEVELS.FATAL.value]: wrapLoggerFunction('error'),
  DEFAULT: wrapLoggerFunction('log')
};

const LOG_WRITTERS = {
  consoleWriter: (msg, level, ...args) => {
    const consoleWriterFn = LOG_LEVEL_CONSOLE_FUNCTION_MAP[level.value] || LOG_LEVEL_CONSOLE_FUNCTION_MAP.DEFAULT;
    consoleWriterFn(msg, ...args);
  }
};

const LOG_FORMATTERS = {
  simpleFormatter: (msg, datetime, level) => {
    return `${ datetime.toString() } [${ level.name }]: ${ msg }`;
  },
  JSONFormatter: (msg, datetime, level) => {
    return JSON.stringify({
      msg,
      datetime,
      level
    });
  }
};

// Logger class
class Logger {
  LEVELS = LOG_LEVELS;

  // save log object: {msg, firstDatetime, lastDatetime, count, level. formattedLog}
  history = [];

  constructor(options = {}) {
    this.log = this.log.bind(this);
    this.latestEntries = this.latestEntries.bind(this);

    // Copy the above parameters into the instance
    Object.assign(this, {
      logLevel: LOG_LEVELS.ERROR.name,
      logWriter: 'consoleWriter',
      logFormatter: 'simpleFormatter',
      logMaxEntries: 20,
      development: false
    }, options);
  }

  clear() {
    this.history = [];
  }

  log(level, msg, ...args) {
    const logLevel = LOG_LEVELS[this.logLevel];
    const write = LOG_WRITTERS[this.logWriter];
    const format = LOG_FORMATTERS[this.logFormatter];
    const datetime = new Date();

    if (level.value >= logLevel.value) {
      const message = format(msg, datetime, level);
      write(message, level, ...args);
    }

    // remove the redundant logs and save to log history, regardless of logLevel
    // The history is sent up to the server when something goes wrong and we want more than just ERRORs at that point

    this.addLogsToHistory(msg, datetime, level);

    while (this.history.length > this.logMaxEntries) {
      this.history.pop();
    }
  }

  addLogsToHistory(newMsg, datetime, level) {
    const [lastLog, ...otherLogs] = this.history;

    if (newMsg === lastLog?.msg && level === lastLog?.level) {
      const updatedLog = {
        ...lastLog,
        lastDatetime: datetime,
        count: lastLog.count + 1
      }
      const formattedLog = this.logHistoryFormatter(updatedLog);

      Object.assign(updatedLog, {formattedLog});
      this.history = [
        updatedLog,
        ...otherLogs
      ];
    } else {
      const newLog = {
        msg: newMsg,
        firstDatetime: datetime,
        lastDatetime: datetime,
        count: 1,
        level
      }
      const formattedLog = this.logHistoryFormatter(newLog);

      Object.assign(newLog, {formattedLog});
      this.history = [
        newLog,
        ...this.history
      ];
    }
  }

  info(msg, ...args) {
    this.log(LOG_LEVELS.INFO, msg, ...args);
  }

  debug(msg, ...args) {
    this.log(LOG_LEVELS.DEBUG, msg, ...args);
  }

  warn(msg, ...args) {
    this.log(LOG_LEVELS.WARN, msg, ...args);
  }

  error(msg, ...args) {
    this.log(LOG_LEVELS.ERROR, msg, ...args);
  }

  dev(...args) {
    if (this.development) {
      this.log(...args);
    }
  }

  devInfo(...args) {
    this.dev(LOG_LEVELS.INFO, ...args);
  }

  devDebug(...args) {
    this.dev(LOG_LEVELS.DEBUG, ...args);
  }

  devWarn(...args) {
    this.dev(LOG_LEVELS.WARN, ...args);
  }

  devError(...args) {
    this.dev(LOG_LEVELS.ERROR, ...args);
  }

  assert(msg, ...args) {
    if (this.development) {
      window.console.assert(msg, ...args);
    }
  }

  logHistoryFormatter(log) {
    if (!log) {
      return null;
    }

    const format = LOG_FORMATTERS[this.logFormatter];
    const {
      msg,
      firstDatetime,
      lastDatetime,
      count,
      level
    } = log;
    const millisecond = Math.abs(lastDatetime - firstDatetime);
    const message = format(msg, firstDatetime, level);

    if (count === 1) {
      return message;
    }

    return `${ message } (x${ count } in ${ millisecond }ms)`;
  }

  latestEntries() {
    return this.history.map((log) => {
      return log['formattedLog'];
    })
  }
}

module.exports = Logger;
